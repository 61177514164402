const environment = process.env.REACT_APP_ENVIRONMENT || 'dev';

const environments = {
  dev: {
    redirectUri: 'http://localhost:3000',
    clientId: '3189f7d2-2df7-464b-8583-81f5f10cd198',
    authority: 'https://login.microsoftonline.com/505cca53-5750-4134-9501-8d52d5df3cd1',
  },
  dev2: {
    redirectUri: 'https://docs.businessid-dev.daimlertruck.com/',
    clientId: '3189f7d2-2df7-464b-8583-81f5f10cd198',
    authority: 'https://login.microsoftonline.com/505cca53-5750-4134-9501-8d52d5df3cd1',
  },
  int: {
    redirectUri: 'https://docs.businessid-int.daimlertruck.com/',
    clientId: '3189f7d2-2df7-464b-8583-81f5f10cd198',
    authority: 'https://login.microsoftonline.com/505cca53-5750-4134-9501-8d52d5df3cd1',
  },
  qa: {
    redirectUri: 'https://docs.businessid-qa.daimlertruck.com/',
    clientId: '3189f7d2-2df7-464b-8583-81f5f10cd198',
    authority: 'https://login.microsoftonline.com/505cca53-5750-4134-9501-8d52d5df3cd1',
  },
  prod: {
    redirectUri: 'https://docs.businessid.daimlertruck.com',
    clientId: '3189f7d2-2df7-464b-8583-81f5f10cd198',
    authority: 'https://login.microsoftonline.com/505cca53-5750-4134-9501-8d52d5df3cd1',
  }
};

const config = environments[environment];

export const msalConfig = {
  auth: {
    clientId: config.clientId,
    authority: config.authority,
    redirectUri: config.redirectUri,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  }
}

export const loginRequest = {
  scopes: ["openid"]
}